@import 'variables';
@import '~bootstrap/scss/bootstrap-grid';
@import '~antd/dist/antd.css';

body {
  background-color: $appBgColor;
}

.fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  min-width: 100vw;
}

#header {
  height: 50px;
  background-color: #ffffff;
  //border-bottom: 1px solid #eeeeee;
  box-shadow: 0 4px 5px -2px #aaaaaa;

  #headerCompanyName {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
  }
}

#primaryNavBar {
  background-color: #1890ff;
  margin-bottom: 15px;
}

.contentBox {
  background: #ffffff;
  padding: 20px;
  border: 1px solid #EEEEEE;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-input-number {
  width: 120px !important;
}
